import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'AreaFormatter',
  standalone: true,
})
export class AreaFormatterPipe implements PipeTransform {

  transform(value: any, unit: string = 'm²'): string {
    const numeroFormatado = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);

    return `${numeroFormatado} ${unit}`;
  }


  transformNumber(value: any): number {
    // Multiplicamos por 10^4 (10000) para truncar até quatro casas decimais
    const factor = Math.pow(10, 4);
    const trucarValor = Math.floor(value * factor) / factor;

    // Formatamos o número para exibir no formato `pt-BR`, usando quatro casas decimais
    const numeroFormatado = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    }).format(trucarValor);

    //PEGA A VIRGULA DA STRING EXEMPLO 0,1 E TRANSFORMA EM 0.1 NUMERICO
    return parseFloat(numeroFormatado.replace(',', '.'));
  }




}
